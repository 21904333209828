import { Language } from '@genteras/ui-lib';
import en_US from 'antd/lib/locale/en_US';
import id_ID from 'antd/lib/locale/id_ID';
import ja_JP from 'antd/lib/locale/ja_JP';
import ko_KR from 'antd/lib/locale/ko_KR';
import mn_MN from 'antd/lib/locale/mn_MN';
import ms_MY from 'antd/lib/locale/ms_MY';
import th_TH from 'antd/lib/locale/th_TH';
import vi_VN from 'antd/lib/locale/vi_VN';
import zh_CN from 'antd/lib/locale/zh_CN';
import zh_TW from 'antd/lib/locale/zh_TW';

// A file to contain the maddening localisation boilerplate.

export const LANGUAGE_LIST = [
  {
    id: Language.en_US,
    title: 'English',
    shortTitle: 'EN',
    locale: 'en-US',
  },
  {
    id: Language.th_TH,
    title: 'ไทย',
    shortTitle: 'TH',
    locale: 'th-TH',
  },
  {
    id: Language.zh_TW,
    title: '中文（繁體）',
    shortTitle: 'TW',
    locale: 'zh-TW',
  },
  {
    id: Language.zh_CN,
    title: '中文（简体）',
    shortTitle: 'CN',
    locale: 'zh-CN',
  },
  {
    id: Language.id_ID,
    title: 'Bahasa Indonesia',
    shortTitle: 'id',
    locale: 'id-ID',
  },
  {
    id: Language.vi_VN,
    title: 'Tiếng Việt',
    shortTitle: 'vi',
    locale: 'vi-VN',
  },
  {
    id: Language.my_MM,
    title: 'မြန်မာဘာသာ',
    shortTitle: 'my',
    locale: 'my-MM',
  },
  {
    id: Language.ms_MY,
    title: 'Malay',
    shortTitle: 'ms',
    locale: 'ms-MY',
  },
  {
    id: Language.ko_KR,
    title: '한국어',
    shortTitle: 'ko',
    locale: 'ko-KR',
  },
  {
    id: Language.ja_JP,
    title: '日本語',
    shortTitle: 'jp',
    locale: 'ja-JP',
  },
  {
    id: Language.mn_MN,
    title: 'Монгол хэл',
    shortTitle: 'mn',
    locale: 'mn-MN',
  },
];

export function lookupAntdLocale(newLanguage: Language) {
  switch (newLanguage) {
    case Language.zh_CN:
      return zh_CN;
    case Language.zh_TW:
      return zh_TW;
    case Language.en_US:
      return en_US;
    case Language.th_TH:
      return th_TH;
    case Language.id_ID:
      return id_ID;
    case Language.vi_VN:
      return vi_VN;
    // need to upgrade antd to support my_MM
    case Language.my_MM:
      return en_US;
    case Language.ms_MY:
      return ms_MY;
    case Language.ko_KR:
      return ko_KR;
    case Language.ja_JP:
      return ja_JP;
    case Language.mn_MN:
      return mn_MN;
  }
}
