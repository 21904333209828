import styled from '@emotion/styled';
import { Layout } from 'antd';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import config, { Sites } from '../../config';
import type { SiteConfig } from '../../config';
import { commonConfig } from '../../features/system/common.config';

const { Footer } = Layout;

const FooterCtn = styled(Footer)({
  textAlign: 'center',
  minHeight: 120,
  background: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '20vh',
});

export const MainFooter: FC = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);

  const [siteConfig, setSiteConfig] = useState<SiteConfig>({
    siteName: '',
    logoHeaderAdmin: '',
    logoHeaderIndex: '',
    logoSmall: '',
    agentSiteLogo: '',
    agentSiteLink: '',
  });
  useEffect(() => {
    setSiteConfig(
      config.sitesConfig.get(window.location.hostname as Sites) ||
        config.sitesConfig.get(Sites.DEFAULT)!
    );
  }, []);
  return (
    <FooterCtn>
      <div>
        <div
          style={{
            maxWidth: 900,
            margin: 'auto',
            whiteSpace: 'pre-line',
          }}
        >
          {t('common:UserNoticesContent')}
        </div>
        <div>{`${siteConfig.siteName} © ${new Date().getFullYear()}`}</div>
      </div>
    </FooterCtn>
  );
};
