const iouWinLoss =
  process.env['NEXT_PUBLIC_USE_IOU_INTERVAL_WIN_LOSS'] === 'true';

export const urls = {
  casa98: '/casa98',
  boOverview: '/bo/subagents',
  boSubAgents: '/bo/subagents',
  boResetPassword: '/bo/setting/reset-password',
  // report
  boPlayerGameRecord: '/bo/reports/player-game-records',
  boGameHistory: '/bo/reports/game-history',
  boPlayerSportsRecord: '/bo/reports/player-sports-records',
  boPlayerSportsRecordUnfinished:
    '/bo/reports/player-sports-records-unfinished',
  boWinlossSummary: `/bo/reports/winloss-summary${iouWinLoss ? '-new' : ''}`,
  boCorrectionsReport: '/bo/reports/corrections-report',
  boTeamPlayReport: '/bo/reports/team-play-report',
  boReportDownload: '/bo/reports/download',
  // history
  boPointsTransferHistory: '/bo/reports/points-transfer-history',
  boPlayerLoginRecord: '/bo/reports/player-login-records',
  boRealTimePlayers: '/bo/reports/real-time-players',
  // setting
  boSettingBasicInfo: '/bo/setting/basic-info',
  boSettingChangePassword: '/bo/setting/change-password',
  play: '/play',
  // announcement
  announcement: '/bo/announcement',
};
