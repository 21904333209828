import type { WhitelabelClient } from '@genteras/core-lib/constants/game-sites.constant';
import type { PermissionName } from '@/lib/api/subagent';
import type { GameSiteSetting } from '../../constant/Enums';
import { axiosSubAgentBase, axiosBase } from '../utils/axiosHelpers';

type LoginData = {
  username: string;
  password: string;
  types: string[];
  recaptchaToken: string;
};

export type UserData = {
  email: string;
  username: string;
  password: string;
};

type ResetPasswordData = {
  password: string;
  resetPasswordToken?: string;
};

type ChangePasswordData = {
  password: string;
  currentPassword: string;
};

export type UpdateUserData = {
  email?: string;
  phoneNumber?: string;
  preferredLanguage?: string;
  preferredTimezone?: string;
  nickname?: string;
};

export type User = {
  id: string;
  email: string;
  username: string;
  phoneNumber: string;
  friendRequestCode: string;
  preferredLanguage: string;
  preferredTimezone: number;
  nickname: string;
  agentCryptoWallet: AgentCryptoWallet;
  type: UserType;
  isEmailVerified: boolean;
  permissions: string[];
  unreadNotificationsCount: number;
  unreadAnnouncementsCount: number;
  balance: number;
  share: number;
  changedShare: number;
  createdAt: Date;
  canLogin: boolean;
  whitelabelClient?: WhitelabelClient;
  gameSiteSettings?: GameSiteSetting[];
  passwordLastReset: Date;
  masterAccount?: User;
};

export type DownstreamSubagent = {
  id: string;
  name: string;
  nickname: string;
  players: DownstreamPlayer[];
};

export type DownstreamPlayer = {
  id: string;
  name: string;
  nickname: string;
};

export enum UserType {
  AGENT = 'Agent',
  SUBACCOUNT = 'Subaccount',
  SUBAGENT = 'Subagent',
}

type AgentCryptoWallet = {
  balance: number;
};

export type SubAccount = {
  id: string;
  username: string;
  permissions: PermissionName[];
};

type LoginResponse = {
  id: string;
};

export type TelegramUrlResponse = {
  url?: string;
  isConnected: boolean;
  telegramUsername?: string;
};

export type UpdateLastNotifiedAtResponse = {
  id: string;
};

type ResetPasswordResponse = {
  status: boolean;
};
type ChangePasswordResponse = {
  status: boolean;
};

const UserAPI = {
  login: async (loginData: LoginData): Promise<LoginResponse> => {
    const response = await axiosBase.post(
      `/auth/login`,
      JSON.stringify(loginData)
    );
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  update: async (updateUser: UpdateUserData): Promise<User> => {
    const response = await axiosSubAgentBase.patch(
      `/subagents/me`,
      JSON.stringify(updateUser)
    );
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  resetPassword: async (
    resetPassword: ResetPasswordData
  ): Promise<ResetPasswordResponse> => {
    const response = await axiosSubAgentBase.post(
      `/subagents/reset-password`,
      JSON.stringify(resetPassword)
    );
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  changePassword: async (
    changePasswordData: ChangePasswordData
  ): Promise<ChangePasswordResponse> => {
    const response = await axiosSubAgentBase.post(
      `/subagents/change-password`,
      JSON.stringify(changePasswordData)
    );
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  logout: async (): Promise<LoginResponse> => {
    const response = await axiosBase.get(`/auth/logout`);
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  disconnectTelegram: async (): Promise<LoginResponse> => {
    const response = await axiosSubAgentBase.delete(`/subagents/me/telegram`);
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  updateLastNotifiedAt: async (): Promise<UpdateLastNotifiedAtResponse> => {
    const response = await axiosSubAgentBase.put(
      `/subagents/me/last-notified-at`
    );
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
};

export default UserAPI;
