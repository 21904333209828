import axios from 'axios';
import { AppConfig } from './AppConfig';

axios.defaults.timeout = 30000;
export const axiosBase = axios.create({
  baseURL: AppConfig.apiBaseUrl,
  headers: { 'Content-Type': 'application/json' },
});

export const axiosSubAgentBase = axios.create({
  withCredentials: true,
  baseURL: AppConfig.subagentApiBaseUrl,
  headers: { 'Content-Type': 'application/json' },
});
