import AieiaLogoBlack from '@/public/images/aieia/logo-header-black.png';
import AieiaLogoSmall from '@/public/images/aieia/logo-s-black.png';
import AieiaLogo from '@/public/images/aieia/logo-white.png';
import AieiaPlayerSiteLogo from '@/public/images/aieia/logo.png';
import BlackAntsLogo from '@/public/images/blackAnts/logo.svg';
import G2LogoSmall from '@/public/images/logo-s.png';
import G2Logo from '@/public/images/logo.png';
import SA36LogoTop from '@/public/images/sa36/logo-top.png';
import WinsplaysLogoSmall from '@/public/images/winsplays/logo-s.png';
import WinsplaysLogo from '@/public/images/winsplays/logo.png';

export enum Sites {
  AIEIA = 'www.aieia.net',
  DEFAULT = 'g2.winsplays.com',
  LOCAL = 'localhost',
  SA36 = 'www.sa36.net',
  BLACKANTS = 'www.blackants.net',
  WINSPLAYS = 'www.winsplays.net',
}

export type SiteConfig = {
  siteName: string;
  logoHeaderIndex: string;
  logoHeaderAdmin: string;
  logoSmall: string;
  agentSiteLogo: string;
  agentSiteLink: string;
};

const sitesConfig: Map<Sites, SiteConfig> = new Map([
  [
    Sites.AIEIA,
    {
      siteName: 'AIEIA',
      logoHeaderIndex: AieiaLogo.src,
      logoHeaderAdmin: AieiaLogoBlack.src,
      logoSmall: AieiaLogoSmall.src,
      agentSiteLogo: AieiaPlayerSiteLogo.src,
      agentSiteLink: 'https://www.aieia.com',
    },
  ],
  [
    Sites.DEFAULT,
    {
      siteName: 'Gaming Genesis',
      logoHeaderIndex: G2Logo.src,
      logoHeaderAdmin: G2Logo.src,
      logoSmall: G2LogoSmall.src,
      agentSiteLogo: WinsplaysLogo.src,
      agentSiteLink: 'https://play.winsplays.com',
    },
  ],
  [
    Sites.LOCAL,
    {
      siteName: 'SA36',
      logoHeaderIndex: SA36LogoTop.src,
      logoHeaderAdmin: SA36LogoTop.src,
      logoSmall: SA36LogoTop.src,
      agentSiteLogo: SA36LogoTop.src,
      agentSiteLink: 'https://www.sa36.com',
    },
  ],
  [
    Sites.SA36,
    {
      siteName: 'SA36',
      logoHeaderIndex: SA36LogoTop.src,
      logoHeaderAdmin: SA36LogoTop.src,
      logoSmall: SA36LogoTop.src,
      agentSiteLogo: SA36LogoTop.src,
      agentSiteLink: 'https://www.sa36.com',
    },
  ],
  [
    Sites.BLACKANTS,
    {
      siteName: 'BlackAnts',
      logoHeaderIndex: BlackAntsLogo.src,
      logoHeaderAdmin: BlackAntsLogo.src,
      logoSmall: BlackAntsLogo.src,
      agentSiteLogo: BlackAntsLogo.src,
      agentSiteLink: 'https://www.blackants.com',
    },
  ],
  [
    Sites.WINSPLAYS,
    {
      siteName: 'Winsplays',
      logoHeaderIndex: WinsplaysLogo.src,
      logoHeaderAdmin: WinsplaysLogo.src,
      logoSmall: WinsplaysLogoSmall.src,
      agentSiteLogo: WinsplaysLogo.src,
      agentSiteLink: 'https://www.winsplays.com',
    },
  ],
]);

export default {
  sitesConfig,
};
