import { GlobalOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Menu } from 'antd';
import { setCookie } from 'cookies-next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { LANGUAGE_LIST } from '@/lib/i18n/Language';

export const LanguageDropdown: FC = () => {
  const router = useRouter();
  const { pathname, query } = router;
  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    setCookie('NEXT_LOCALE', key);
  };

  const MenuItems = LANGUAGE_LIST.map((locale) => ({
    key: locale.id,
    id: locale.id,
    label: (
      <Link href={{ pathname: pathname, query: query }} locale={locale.locale}>
        <a>{locale.title}</a>
      </Link>
    ),
  }));

  const languageMenu = <Menu items={MenuItems} onClick={handleMenuClick} />;

  return (
    <Dropdown overlay={languageMenu} placement="bottomRight">
      <span className="lang-container">
        <GlobalOutlined className="language-icon" />
      </span>
    </Dropdown>
  );
};
