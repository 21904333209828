import type { I18nActiveNamespaces } from '@/lib/i18n';

export type CommonConfig = {
  // Define installed namespaces in the type here
  // to allow full typechecking of your translation keys.
  i18nNamespaces: Readonly<I18nActiveNamespaces<'common'>>;
};

export const commonConfig: CommonConfig = {
  i18nNamespaces: ['common'],
} as const;
